import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { graphql } from "gatsby"
//import { Layout } from "../../../components/layout"
// import { ProductListing } from "../../components/product-listing"
import GridProductsLayout from "../../components/layout/grid-products"

import { Seo } from "../../components/helmet/seo"
import slugify from "@sindresorhus/slugify"
import { MoreButton } from "../../components/more-button"

import layoutData from '../../utils/layout.config.json'

export default function Products({
  data: { products },
  pageContext: { vendor },
}) {
  const ldata = layoutData.productVendor
  return (
    <Container fluid>
      <Row className="px-2 px-md-3">
        <Col>
          <Seo title={`${vendor} products`} />
          <div className="mt-2 mb-5">
            <h1 >{vendor}</h1>
            <small>Brand Products</small>
          </div>
          
        </Col>
      </Row>
      {
        products.group.map((item, index) => {
          return <div key={index} className="mt-4">
            <Row className="px-2 px-md-3">
              <Col>
                <h2>{item.fieldValue}</h2>
              </Col>
            </Row>
            <GridProductsLayout row={ldata.FKC.rowCount.md} gridType={ldata.FKC.layout} products={item.nodes} />
          </div>
        })
      }
      <Row className="d-flex justify-content-center mt-5">
        <Col sm={6} className="mx-auto">
          {products.pageInfo.hasNextPage && (
            <MoreButton to={`/search?v=${slugify(vendor)}`}>
              More Products
            </MoreButton>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export const query = graphql`
  query($vendor: String!) {
    products: allShopifyProduct(
      filter: { vendor: { eq: $vendor } }
      sort: { fields: publishedAt, order: DESC }
      limit: 24
    ) {
      group(field: productType) {
        field
        fieldValue
        nodes{
          ...ProductCard
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`
